.dmagafy-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    margin-top: 15px; /* Adjust for desired space */
  }
  
  .dmagafy-row-1 {
    height: 100px;
  }
  
  .dmagafy-row-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  
  .dmagafy-row-2 img {
    border-radius: 50%;
    height: 158px;
    width: 158px;
  }
  
  .dmagafy-row-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 100px;
  }
  
  .dmagafy-row-3 h1 {
    font-family: 'Jockey One', sans-serif;
    color: rgba(255, 255, 255, 1);
    font-size: 80px;
    line-height: 111.84px;
    font-weight: 400;
  }
  
  .dmagafy-row-3 span {
    color: rgba(219, 10, 37, 1);
    text-decoration: none;
  }
  
  .call-to-action {
    font-family: 'Jockey One', sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 56px; /* Adjusted line-height */
    color: rgba(255, 255, 255, 1);
    text-align: center; /* Center the text */
    margin-top: -80px; /* Adjust margin if needed to position correctly */
}
  
  .dmagafy-row-4, .dmagafy-row-5 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
  }
  
  
  .dmagafy-row-4 p, .dmagafy-row-5 p {
    font-family: 'Jockey One', sans-serif;
    color: rgba(206, 194, 194, 1);
    font-weight: 400;
    font-size: 24px;
    line-height: 33.55px;
  }

  .dmagafy-row-5 p {
    margin-bottom: 250px;
  }
  
  .dmagafy-row-4 span {
    color: rgba(219, 10, 37, 1);
    text-decoration: none;
  }
  
  
  .dmagafy-row-6 a {
    width: 590px;
    height: 74px;
    background: rgba(219, 10, 37, 1);
    box-shadow: 0px 1px 10px 0px rgba(219, 10, 37, 1);
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Jockey One', sans-serif;
    font-size: 24px;
    color: rgba(255, 255, 255, 1);
    margin-top: -180px;
  }
  
  .dmagafy-row-6 a:hover {
    background: rgb(245, 20, 46);
    box-shadow: 0px 1px 10px 0px rgba(245, 20, 37, 1);
  }
  
  /* Media Query for Mobile Devices (up to 800px) */
  @media (max-width: 1024px) {
    .dmagafy-section {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      overflow: hidden;
      padding-bottom: 0;
    }

    .dmagafy-row-1 {
      height: 30px;
    }
  
    .dmagafy-row-2 img {
      height: 75px;
      width: 75px;
    }

    .dmagafy-row-3 {
      margin-top: 50px;
    }
  
    .dmagafy-row-3 h1 {
      font-size: 60px;
    }
  
    .dmagafy-row-4 p, .dmagafy-row-5 p {
      font-size: 17px;
    }
  
    .dmagafy-row-6 a {
      width: 100%;
      font-size: 18px;
    }
  }
  