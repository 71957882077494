.posts-section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 15px;
    position: relative; /* To position the overlay properly */
    overflow: hidden;
    padding-bottom: 40px;
}

.posts-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1; /* Ensure the overlay is above the background image */
    pointer-events: none; /* Ensure the overlay doesn’t block interaction with content */
}

.posts-title-row,
.posts-screenshots-row,
a {
    position: relative;
    z-index: 2; /* Ensure the content is above the overlay */
}

.posts-title-row {
    display: flex; /* Use flexbox to align elements inline */
    align-items: center; /* Vertically center the content */
    justify-content: center; /* Center horizontally */
    gap: 30px; /* Space between the h1 and img */
    font-family: 'Bierg Artenlt', sans-serif;
    font-size: 40px;
    font-weight: 300;
    line-height: 61.12px;
    color: rgba(255, 255, 255, 1);
    text-align: center; /* Keep the text aligned centrally */
}

.posts-title-row img {
    margin-top: 10px;
    width: 60px;
    height: 60px;
}

.posts-screenshots-wrapper {
    width: 100%;
    overflow: hidden;
    margin-top: 100px;
  }
  
  .posts-slider {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  
  .posts-slider {
    animation: scroll 30s linear infinite;
  }
  
  .post-screenshot {
    flex: 0 0 auto;
    margin-right: 24px;
  }
  
  .post-screenshot img {
    width: 500px; /* Adjust the size as needed */
    height: 500px;
    border-radius: 4.91px;
    opacity: 1;
    box-shadow: 0px 3.27px 3.27px rgba(0, 0, 0, 0.25);
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-50%));
    }
  }
  
  
  /* Adjust the animation duration based on the total width */
  .posts-slider:hover {
    animation-play-state: paused;
  }

.posts-link {
    width: 300px; /* Set width to 376px */
    height: 30px; /* Set height to 74px */
    padding: 20px 16px; /* Adjust padding */
    border-radius: 2px 0px 0px 0px; /* Apply border-radius */
    border: 3px solid rgba(206, 194, 194, 1); /* Set border thickness and color */
    background-color: transparent; /* Ensure background is transparent */
    text-decoration: none; /* Remove text underline */
    font-size: 24px; /* Text size */
    font-weight: 400; /* Font weight */
    line-height: 33.55px; /* Line height */
    font-family: 'Jockey One', sans-serif; /* Font family */
    color: rgba(255, 255, 255, 1); /* Text color */
    display: flex;
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Horizontally center the text */
    opacity: 1; /* Ensure it's visible */
    transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
    box-shadow: none; /* Remove box-shadow */
    margin-top: 60px;
    margin-bottom: 24px;
}

.posts-link:hover {
    background-color: rgba(255, 255, 255, 1); /* White background on hover */
    color: rgba(219, 10, 37, 1); /* Change text color to match primary color on hover */
    transform: scale(1.05); /* Slightly increase size on hover */
}


/* Media Query for Mobile Devices (up to 800px) */
@media (max-width: 1024px) {

    .posts-title-row {
        font-size: 24px;
    }

    .posts-title-row img {
        width: 40px;
        height: 40px;
    }

    .posts-screenshots-wrapper {
        width: 100%;
        overflow: hidden;
        margin-top: 450px;
    }


    .post-screenshot img {
        width: 300px; /* Smaller size for mobile */
        height: 300px;
    }

    .posts-link {
        font-size: 20px;
        margin-bottom: 0px;
        margin-top: 20px;
    }
}
