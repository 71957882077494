.info-section {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

    margin-top: 30px;
    /* Adjust for desired space */
}

.buy-subsection {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: white;
    z-index: 1;
}


.buy-row-1,
.buy-row-2,
.buy-row-3,
.buy-row-4,
.buy-row-5,
.buy-row-6,
.buy-row-7,
.dmagafy-row-1,
.dmagafy-row-2,
.dmagafy-row-3,
.dmagafy-row-4,
.dmagafy-row-5,
.dmagafy-row-6 {
    width: 100%;
    height: 100px;
    /* Adjust row height as needed */
    margin: 10px 0;
    /* Placeholder background */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Optional: border around rows */
}

.buy-row-1 img {
    height: 134px;
    width: 134px;
    margin-top: 150px;
}

.buy-row-3 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.info-contract-address {
    display: flex;
    flex-direction: row;
    margin-bottom: -350px;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-family: 'Jockey One', sans-serif;
    color: rgba(206, 194, 194, 1);
    font-size: 16px;
    margin-left: 20px;
}

.info-ca-image {
    height: 20px;
    width: auto;
}

.info-copy-image {
    height: 20px;
    width: auto;
    margin-left: -30px;
    cursor: pointer;
}

.buy-row-3 h1 {
    font-family: 'Jockey One', sans-serif;
    font-size: 80px;
    line-height: 111.84px;
    text-align: center;
    color: rgba(219, 10, 37, 1);
    font-weight: 400;
    margin-bottom: 100px;
}

.buy-row-4 h1 {
    font-family: 'Jockey One', sans-serif;
    font-size: 40px;
    line-height: 55.92px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    font-weight: 400;
    margin-bottom: 120px;
}

.info-copy-button {
    background: transparent;
    border: none;
    margin-left: 25px;
}


.info-copied-text {
    text-decoration: none;
    font-family: 'Jockey One', sans-serif;
    font-size: 18px;
}

.info-buy-button {
    width: 658px;
    height: 74px;
    background: rgba(219, 10, 37, 1);
    box-shadow: 0px 1px 10px 0px rgba(219, 10, 37, 1);
    text-decoration: none;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    justify-content: center;
    font-family: 'Jockey One', sans-serif;
    font-size: 24px;
    color: rgba(255, 255, 255, 1);
    margin-top: 50px;
}

.info-buy-button:hover {
    background: rgb(245, 20, 46);
    box-shadow: 0px 1px 10px 0px rgba(245, 20, 37, 1);
}

/* Ensure the anchor and OR container are stacked vertically */
.buy-row-6 {
    flex-direction: column;
    /* Stack the content vertically */
    align-items: center;
    /* Center align the content */
    justify-content: center;
    position: relative;
    /* To allow positioning of the anchor independently */
}

.contract-address {
    font-size: 24px;
    font-family: 'Jockey One', sans-serif;
    font-weight: 400;
    line-height: 33.55px;
    text-align: left;
    color: rgba(206, 194, 194, 1);
    position: relative;
    top: -30px;
    /* Move the anchor up independently */
}

/* Style the container for OR text and lines */
.or-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 40px;
    /* Space between the text and the lines */
    margin-bottom: 0;
    /* Ensure no margin affects the OR container */
    margin-top: 30px;
}

.or-container h1 {
    font-size: 32px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    line-height: 44.74px;
    font-family: 'Jockey One', sans-serif;
}

/* Style the lines on either side of the OR text */
.or-container .line {
    height: 1px;
    /* Line thickness */
    background: rgba(255, 255, 255, 1);
    width: 280px;
}

.buy-row-7 {
    display: flex;
    justify-content: center;
    /* Center the buttons horizontally */
    align-items: center;
    /* Vertically center the buttons */
    gap: 24px;
    /* Gap between the two buttons */
    width: 700px;
    margin-top: 20px;
    /* Optional: adjust as needed */
}


/* Button styles */
.buy-row-7 a {
    width: 317px;
    height: 74px;
    background-color: transparent;
    /* Transparent background */
    color: rgba(255, 255, 255, 1);
    /* White text color */
    text-align: center;
    /* Center text horizontally */
    display: flex;
    /* Use flexbox */
    justify-content: center;
    /* Horizontally center the text */
    align-items: center;
    /* Vertically center the text */
    font-family: 'Jockey One', sans-serif;
    font-size: 24px;
    font-weight: 400;
    text-decoration: none;
    /* Remove underline */
    border: 2px solid rgba(255, 255, 255, 1);
    /* White rectangular border */
    border-radius: 0px;
    /* Ensure the border is rectangular (no rounded corners) */
    transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
    box-shadow: none;
    /* Remove box-shadow */
    overflow: hidden;
    margin-top: 10px;
}


/* Hover effect for the buttons */
.buy-row-7 a:hover {
    background-color: rgba(255, 255, 255, 1);
    /* White background on hover */
    color: rgba(219, 10, 37, 1);
    /* Change text color to match primary color on hover */
    transform: scale(1.05);
    /* Slightly increase size on hover */
}


.widget-subsection {
    width: 800px;
    margin-top: 95px;
}

.price-ticker-widget {
    display: none;
}

.info-content-right {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.info-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    gap: 30px;
}



/* Media Query for Mobile Devices (up to 800px) */
@media (max-width: 1024px) {
    .info-section {
        flex-direction: column;
        height: auto;
        padding: 0;
        /* Remove padding that could cause overflow */
        overflow-x: hidden;
        /* Prevent horizontal scrolling */
    }

    .buy-subsection {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        /* Ensure padding does not cause overflow */
    }

    .contract-address {
        font-size: 14px;
    }

    .contract-address img {
        width: 18px;
        height: auto;
        margin: 0;
    }

    .info-copy-button {
        margin: 0;
    }

    .info-copied-text {
        font-size: 8px;
    }

    .buy-row-4 h1 {
        font-size: 26px;
    }


    .buy-row-1 img,
    .dmagafy-row-2 img {
        height: 100px;
        width: 100px;
    }


    .info-buy-button,
    .dmagafy-row-6 a {
        width: 100%;
        /* Make buttons full width */
        font-size: 18px;
        /* Adjust button text size for mobile */
    }

    .buy-row-7 {
        width: 100%;
    }

    .buy-row-7 a {
        width: 100%;
        /* Full width buttons */
        font-size: 18px;
    }

    .widget-subsection {
        width: 100%;
        margin-bottom: 0;
    }

    .info-content {
        flex-direction: column;
    }

    .info-content-right {
        width: 100%;
    }

    .price-chart-widget {
        display: none;
    }

    .price-ticker-widget {
        display: block;
    }


}