.presentation-section {
    display: flex;
    flex-direction: row;
    height: 880px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    margin-top: 15px; /* Adjust for desired space */
}


.left-column, .right-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    justify-content: center;
}

.left-column p, .right-column p {
    font-family: 'Bierg Artenlt', sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 36.67px;
    color: rgba(255, 255, 255, 1);
}

.maga-span {
    text-decoration: none;
    font-size: 34px;
    line-height: 51.95px;
}

.dark-maga-span {
    text-decoration: none;
    font-size: 40px;
    line-height: 61.12px;
    color: rgba(219, 10, 37, 1);
}

.left-column img, .right-column img {
    margin-top: 80px;
}

.spacer {
    height: 50px;
}


/* Media Query for Mobile Devices (up to 800px) */
@media (max-width: 1024px) {
    .presentation-section {
        flex-direction: column; /* Stack columns vertically for mobile */
        height: auto;
        padding: 0;
        overflow-x: hidden; /* Prevent horizontal scrolling */
        padding-bottom: 24px;
    }

    .left-column, .right-column {
        width: 100%; /* Make columns full width on mobile */
        padding: 20px;
        box-sizing: border-box; /* Ensure padding does not cause overflow */
    }

    .left-column img, .right-column img {
        margin-top: 50px;
    }

    .left-column p, .right-column p {
        font-size: 16px;
        text-align: center;
    }

    .maga-span {
        font-size: 28px;
        line-height: 40px;
    }

    .dark-maga-span {
        font-size: 32px;
        line-height: 45px;
    }

    .spacer {
        height: 30px;
    }

    .video-container {
        width: 100%; /* Ensure video player is full width */
        height: auto; /* Maintain aspect ratio */
        max-width: 100%; /* Prevent overflow */
    }
}