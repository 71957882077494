.guide-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Ensure content is centered horizontally */
    justify-content: center;
    /* Ensure vertical centering */
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    margin-top: 15px;

    padding-bottom: 32px;
}

/* Title */
.title-row {
    text-align: center;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    /* Center title */
    width: 100%;
    /* Take full width */
}

.title-row h1 {
    font-family: 'Jockey One', sans-serif;
    font-size: 32px;
    color: white;
    text-align: center;
    /* Center text inside the row */
}

/* Buttons */
.buttons-row {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;
    padding: 10px;
    border: 1px solid rgba(219, 10, 37, 1);
    /* Add thin border around the grid */
    width: 100%;
    max-width: 500px;
    z-index: 100;
}

.guide-button,
.guide-button-selected {
    width: 162px;
    height: 74px;
    font-family: 'Jockey One', sans-serif;
    font-size: 24px;
    text-align: center;
    border: none;
    cursor: pointer;
}

.guide-button {
    background: transparent;
    color: white;
    text-shadow: 0px 1px 10px rgba(219, 10, 37, 1);
    /* Apply shadow to text only */
    border: none;
    /* No border on the button itself */
}

.guide-button-selected {
    background: rgba(219, 10, 37, 1);
    color: white;
    box-shadow: none;
    /* Remove the button box shadow */
    text-shadow: none;
    /* Remove text shadow for selected button */
}

.guide-button:hover {
    background: rgba(245, 20, 46, 0.8);
    color: white;
}

/* Content Layout */
.guide-content {
    display: flex;
    justify-content: center;
    /* Center the content horizontally */
    align-items: flex-start;
    padding: 20px;
    max-width: 1400px;
    /* Constrain width to center the content */
    width: 100%;
    box-sizing: border-box;
}


.guide-left,
.guide-right {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.guide-left {
    align-items: flex-start
}

.guide-right {
    justify-content: center;
    align-items: center;
    /* Center video vertically */
}

.guide-title {
    display: flex;
    align-items: center;
    gap: 20px;
    /* Reduce the gap between the image and text to fit everything on one line */
    justify-content: center;
    /* Center the content */
    margin-left: 0;
    /* Remove left margin */
    margin-top: 100px;
    flex-wrap: nowrap;
    /* Ensure the elements stay on one line */
    margin-left: 100px;
}

.guide-title h1 {
    font-size: 32px;
    /* Reduce font size slightly to ensure everything fits on one line */
    font-family: 'Jockey One', sans-serif;
    color: rgba(255, 255, 255, 1);
    line-height: 40px;
    /* Adjust line height */
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.guide-title img {
    width: 60px;
    /* Adjust size of the image */
    height: 60px;
}

.guide-left p {
    font-family: 'Jockey One', sans-serif;
    color: rgba(206, 194, 194, 1);
    font-size: 24px;
    font-weight: 400;
    line-height: 33.55px;
}

.guide-logo {
    width: 75px;
    height: 75px;
}

.jupyter-link {
    font-family: 'Jockey One', sans-serif;
    color: rgba(206, 194, 194, 1);
    font-size: 30px;
    font-weight: 400;
    line-height: 33.55px;
    margin-left: 40px;
}

.step {
    color: rgba(219, 10, 37, 1);
    text-decoration: none;
    margin-right: 20px;
}

.step-1 {
    margin-left: 100px;
}

.step-2 {
    margin-left: 130px;
}

.step-3 {
    margin-left: 180px;
}

.step-4 {
    margin-left: 95px;
    text-align: center;
}

.copy-button {
    background-color: transparent;
    border: none;
}

.guide-buy-button {
    width: 526px;
    height: 74px;
    background: rgba(219, 10, 37, 1);
    box-shadow: 0px 1px 10px 0px rgba(219, 10, 37, 1);
    text-decoration: none;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    justify-content: center;
    font-family: 'Jockey One', sans-serif;
    font-size: 24px;
    line-height: 33.55px;
    color: rgba(255, 255, 255, 1);

    margin-left: 130px;
    margin-top: 60px;
}

.guide-buy-button:hover {
    background: rgb(245, 20, 46);
    box-shadow: 0px 1px 10px 0px rgba(245, 20, 37, 1);
}

/* Right side (Phone Image) */
.guide-right {
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
}

.guide-image {
    width: 45%;
    height: auto;
    margin-top: 120px; /* Adjust spacing as needed */
  }

/* Container to hold both the video and the frame */
.phone-container {
    position: relative;
    width: 45%;
    /* Set width to match the iPhone frame */
    display: flex;
    justify-content: center;
    /* Center the video and frame horizontally */
    align-items: center;
    /* Center them vertically if needed */
    margin: 0 auto;
    /* Center the container itself */
    margin-top: 120px;
}


/* Adjust the phone-video */
.phone-video {
    width: 65%;
    /* Keep the width for the video */
    height: auto;
    position: absolute;
    /* Absolute positioning to overlay video */
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Center the video within the container */
    z-index: 1;
    /* Video behind the frame */
}

/* Adjust the iPhone frame */
.iphone-frame {
    position: relative;
    /* Set relative positioning for the frame */
    width: 100%;
    /* Full width of the container */
    height: auto;
    z-index: 2;
    /* Frame on top of the video */
}

/* Mobile Phone Container */
.phone-container-mobile {
    position: relative;
    width: 100%;
    max-width: 350px; /* Adjust as needed */
    height: 500px;
    margin: 0 auto;
    margin-top: 40px; /* Space above the container */
    overflow: hidden;
    display: none;
  }
  
  /* Phone Frame */
  .iphone-frame-mobile {
    position: relative;
    display: block;
    width: 100%; /* Full width of the container */
    height: auto; /* Maintain aspect ratio */
    z-index: 2; /* Ensure the frame is on top */
  }
  
  /* Phone Screen (Wrapper for the video) */
  .phone-screen {
    position: absolute;
    top: 3%;    /* Adjust based on actual measurements */
    left: 10%;   /* Adjust based on actual measurements */
    width: 81%; /* Adjust based on actual measurements */
    height: 92%;/* Adjust based on actual measurements */
    overflow: hidden;
    z-index: 1;
  }
  
  /* Video inside the Phone Screen */
  .phone-video-mobile {
    width: 80%;
    height: 100%;
    object-fit:cover;
  }
  
  .step-content {
    text-decoration: none;
  }


/* Mobile and Tablet (max-width: 1024px) */
/* Mobile and Tablet (max-width: 1024px) */
@media (max-width: 1024px) {
    .guide-section {
        position: relative;
        flex-direction: column;
        align-items: center;
        background-size: cover;
        background-position: center;
        padding: 0;
        height: auto;
        padding-bottom: 100px;
    }

    .guide-content {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-align: center;
    }


    .guide-title {
        margin-left: 0;
        margin-top: 20px;
        text-align: center;
        gap: 20px;
        align-items: center;
    }

    .buttons-row {
        margin: 0;
        max-width: 300px;
        gap: 5px;
        text-decoration: none;
    }

    .guide-button,
    .guide-button-selected {
        width: 140px;
        height: 60px;
        font-family: 'Jockey One', sans-serif;
        font-size: 18px;
        text-align: center;
        border: none;
        cursor: pointer;
    }

    .guide-button-selected {
        max-width: 80%;
        max-height: 80%;
    }
    

    .guide-title h1 {
        font-size: 16px;
    }

    .guide-title img {
        width: 50px;
        height: 50px;
    }

    .guide-left p {
        font-size: 18px;
        text-align: center;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .jupyter-link {
        font-size: 20px;
        margin-left: 20px;
    }


    .step {
        margin: 0;
    }

    .guide-buy-button {
        width: 90%;
        margin-left: 20px;
        margin-top: 30px;
        font-size: 20px;
    }

    .step-1,
    .step-2,
    .step-3,
    .step-4 {
        margin-left: 0;
    }

    /* Position the video and phone below the buy button */
    .guide-right {
        position: relative;
        top: auto;
        /* Reset the top positioning */
        left: 0;
        right: 0;
        bottom: 0;
        margin-top: 20px;
        /* Space above the phone container */
        z-index: 0;
        display: flex;
        justify-content: center;
    }
      

    /* Hide desktop phone container and show mobile version */
    .phone-container {
        display: none;
    }

    .guide-image {
        width: 350px;
        height: auto;
        margin-top: 40px;
      }

    .phone-container-mobile {
        display: flex;
        /* Show the mobile phone container */
    }

    .guide-left {
        position: relative;
        z-index: 1;
    }
}