/* Footer base styles */
.footer {
    display: flex;
    justify-content: space-around;
    max-width: 100%;
    padding: 10px 200px;
    background: rgba(0, 0, 0, 1);
    color: rgba(206, 194, 194, 1);
    font-family: 'Inter', sans-serif;
    align-items: center;
}

/* Footer columns */
.footer-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.mint-address {
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 800;
}

.mint-address img {
    height: 16px;
    width: 12px;
}

.footer-copy-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.footer-meta {
    width: 50%;
    text-align: center;
    padding: 10px;
    border-left: 3px solid;
    border-right: 3px solid;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 800;
}

.footer-meta-mobile {
    display: none;
}

.footer-copied-text {
    text-decoration: none;
    font-size: 10px;
    margin-left: 10px;
}

.social-column {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

/* Social column links */
.social-column .footer-links img {
    width: 24px;
    height: 24px;
}

/* Media Query for mobile screens */
@media (max-width: 1024px) {
    .footer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0px;
        padding: 40px;
        text-align: center;
    }

    .footer-meta {
        display: none;
    }

    .footer-meta-mobile {
        display: block;
        width: 100%;
        grid-column: 1 / -1; /* Span across all columns */
        text-align: center;
        font-family: 'Jockey One', sans-serif;
        font-size: 10px;
        margin-top: 20px; /* Add spacing above the mobile meta text */
    }

    .mint-address {
        justify-content: center;
        gap: 5px;
        text-align: center;
        font-size: 10px;
    }

    .footer-links {
        font-size: 10px;
        margin: 0;
    }

    .social-column .footer-links img {
        width: 16px;
        height: 16px; /* Adjust icon sizes */
        gap: 10px;
    }

    .footer-copy-button img {
        width: 12px;
        height: 12px;
    }

    .footer-column {
        gap: 20px;
    }

    .footer-copied-text {
        font-size: 9px;
    }
}
