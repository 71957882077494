/* src/pages/HeroSection/HeroSection.css */
/* Variables */
:root {
    --color-red: rgba(219, 10, 37, 0.25);
    --color-dark: rgba(0, 0, 0, 0.5);
}

.hero-section h1 {
  font-weight: normal;
}

.hero-section {
    position: relative; /* To allow for absolute positioning of the video */
    width: 100%; /* Fill the available width */
    height: auto; /* Use auto to allow it to adjust based on content, like Figma's "Hug" */
    min-height: 800px; /* Match Figma's minimum height */
    padding: 24px 0px 200px 0px; /* Match Figma's padding */
    gap: 80px; /* Ensure spacing between child elements */
    opacity: 1; /* Ensure it's visible, set to 0 only if you need an initial animation */
    margin: 0 auto; /* Center the hero section horizontally */
    overflow: hidden; /* To handle the video overflow */
}


.hero-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hero-banner-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the entire section without stretching */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; /* Puts the video behind other content */
}

.hero-banner-video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

.hero-banner-video {
  pointer-events: none;
}


.hero-container {
    position: absolute;
    z-index: 1; /* Ensures this content is displayed above the video */
    background-color: var(--color-dark);
    width: 100%;
    height: 100%; /* This can be adjusted based on your content */
    object-fit: cover;
    top: 0;
    left: 0;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.row-1, .row-2, .row-3, .row-4, .row-5, .row-6 {
    display: flex;
    justify-content: center; /* Center content inside the rows */
    align-items: center; /* Vertically center content */
    width: 80%; /* Adjust the width as needed */
    height: auto; /* Allow the height to adjust dynamically */
    padding: 10px;
    /*max-height: 200px; /* Prevent rows from expanding beyond a certain height */
}

.row-1 {
    min-height: 70px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 200;
}

.row-1 img {
    height: 30px;
    width: auto;
    z-index: 200;
}

.sound-button-1 {
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 200;
  }
  
  .sound-on, .sound-off {
    height: 30px;
    width: auto;
    z-index: 200;
    margin-bottom: 50px;
  }
  
  .sound-off {
    display: none; /* Default hide mute button */
  }
  
  .hero-section .row-1 .sound-button-1 .sound-off {
    display: block;
    z-index: 1000;
  }
  
  /* You can add custom styles for hover or focus states */
  .sound-button-1 img:hover {
    opacity: 0.8;
  }

.row-2, .row-3 {
    font-family: 'Bierg Artenlt', sans-serif;
    font-size: clamp(100px, 9vw, 220px); /* Dynamically adjust the font size */
    text-align: center;
    font-weight: normal;
    white-space: nowrap; /* Prevent text wrapping */
    text-overflow: ellipsis; /* Add ellipsis if needed */
    height: 220px; /* Match the row height */
    line-height: 200px; /* Match line height to row height */
    display: flex;
    justify-content: center;
    align-items: center; /* Ensure vertical centering */

}


.row-4 h3 {
    font-weight: normal;
}

.row-2 {
    color: rgba(255, 255, 255, 1);
}

.row-3 {
    color: rgba(219, 10, 37, 1);
}

.row-4 {
    color: rgba(206, 194, 194, 1);
    font-family: 'Bierg Artenlt', sans-serif;
    font-size: clamp(25px, 3vw, 20px); /* Reduce the maximum size */
    text-align: center;
    line-height: 1.1; /* Adjust the line height to be proportional to the text size */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide any overflowing text */
    text-overflow: ellipsis; /* Add ellipsis if needed */
    max-height: 200px; /* Ensure the text doesn't exceed the row height */
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-4 h3 {
  margin-top: 60px;
}

.row-5 {
    text-align: center;
    font-weight: 300;
    line-height: 1.1; /* Adjust the line height to be proportional to the text size */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide any overflowing text */
    text-overflow: ellipsis; /* Add ellipsis if needed */
    /*max-height: 200px; /* Ensure the text doesn't exceed the row height */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 40px;
}

.row-6 {
    text-align: center;
    font-weight: 300;
    line-height: 1.1; /* Adjust the line height to be proportional to the text size */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide any overflowing text */
    text-overflow: ellipsis; /* Add ellipsis if needed */
    /*max-height: 100px; /* Ensure the text doesn't exceed the row height */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
}

.row-6 a {
    margin-top: 14px;
}

.hero-dmagafy-button {
  width: 518px;
  height: 74px;
  background-color: transparent;
  /* Transparent background */
  color: rgba(255, 255, 255, 1);
  /* White text color */
  text-align: center;
  /* Center text horizontally */
  display: flex;
  /* Use flexbox */
  justify-content: center;
  /* Horizontally center the text */
  align-items: center;
  /* Vertically center the text */
  font-family: 'Jockey One', sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-decoration: none;
  /* Remove underline */
  border: 2px solid rgba(255, 255, 255, 1);
  /* White rectangular border */
  border-radius: 0px;
  /* Ensure the border is rectangular (no rounded corners) */
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
  box-shadow: none;
  /* Remove box-shadow */
  overflow: hidden;
  margin-top: 10px;
}

.hero-dmagafy-button:hover {
  background-color: rgba(255, 255, 255, 1);
  /* White background on hover */
  color: rgba(219, 10, 37, 1);
  /* Change text color to match primary color on hover */
  transform: scale(1.05);
  /* Slightly increase size on hover */
}

.hero-buy-button {
    width: 518px;
    height: 74px;
    background: rgba(219, 10, 37, 1);
    box-shadow: 0px 1px 10px 0px rgba(219, 10, 37, 1);
    text-decoration: none;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    justify-content: center;
    font-family: 'Jockey One', sans-serif;
    font-size: 24px;
    color: rgba(255, 255, 255, 1);
}

.hero-buy-button:hover {
    background: rgb(245, 20, 46);
    box-shadow: 0px 1px 10px 0px rgba(245, 20, 37, 1);
}


.hero-socials img {
    height: 30px; /* Increased height */
    width: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the image doesn't stretch or distort */
    transition: transform 0.3s ease; /* Smooth hover effect */
}

.hero-socials:hover {
    transform: scale(1.1);
}

  
/* Media Queries for Mobile Devices (up to 768px) */
@media (max-width: 1024px) {

    .hero-section {
        min-height: 500px;
    }

    .hero-container {
      padding: 5px;
      gap: 8px; /* Reduce the gap further for mobile */
    }
  
    .row-1 {
        min-height: 100px;
        justify-content: center;
        align-items: center;
    }

    .row-1, .row-2, .row-3, .row-4, .row-5 {
      width: 95%;
      max-height: 100px; /* Keep the row height condensed */
    }
  
    .row-2, .row-3 {
      font-size: clamp(55px, 5vw, 150px); /* Keep the larger font size */
      line-height: 100px; /* Keep the line height proportional */
      max-width: 760px;
    }
  
    .row-4 {
      font-size: clamp(20px, 2.5vw, 25px); /* Keep row 4 font size larger */
      line-height: 1.1;
      max-height: 90px;
    }


    /* Target row-5 on mobile to push it upwards and increase its size */
    .row-5 {
      margin-top: -10px; /* Move row-5 upwards on mobile */
      max-height: 200px; /* Make row-5 slightly larger */
    }
  
    .hero-buy-button {
        height: 70px;
        width: 100%;
        font-size: 23px; /* Slightly larger button */
    }

    .hero-dmagafy-button {
      height: 70px;
      width: 100%;
      font-size: 23px;
    }
  
    .hero-socials img {
      height: 22px; /* Keep icons larger */
    }

    .sound-button-1 {
      margin-bottom: -150px;
    }
}

  


