.navbar {
    background-color: transparent;
    padding: 1rem;
    position: fixed;
    top: 1.5rem; /* Below upper header */
    width: 80%;
    z-index: 30; /* Higher than upper header */
    transition: background-color 0.3s ease, top 0.3s ease;
    font-family: 'Inter', 'sans-serif';
    font-weight: bold;
    gap: 20px;
    z-index: 100;
}

/* Remove list styles and align links in a row */
.nav-links {
    list-style-type: none; /* Remove bullet points */
    display: flex; /* Align items in a row */
    gap: 4rem; /* Space between the links */
    margin: 0;
    padding: 0;
    padding-left: 60px;
}

/* Link styling */
.nav-links li a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.808); /* Default link color */
    font-size: 1.5rem;
    padding-bottom: 5px;
    transition: color 0.3s ease;
    position: relative;
}

/* Add underline to HOME link */
.nav-links li:first-child a {
    border-bottom: 4px solid rgba(255, 255, 255, 0.726); /* Underline for HOME link */
    color: white; /* Default link color */
}


/* Hover effect for the DEX link */
.nav-links li a:hover {
    color: #ccc; /* Change color on hover */
}

.navbar-socials:hover {
    transform: scale(1.1);
}

/* Media Queries for Responsive Design */
/* Tablets (768px - 1024px) */
@media (max-width: 1024px) {

    .navbar {
        width: 100%;
    }
    
    .nav-links {
        gap: 2.5rem; /* Reduce space between links */
        padding-left: 50px;
    }

    .nav-links li a {
        font-size: 1.2rem; /* Reduce font size for smaller screens */
    }

    .navbar-socials img {
        height: 22px; /* Reduce social media icon size */
    }
}

