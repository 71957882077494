/* Video Container */
.video-container {
    position: relative;
    width: 528px; /* Fixed width */
    height: 365px; /* Fixed height */
    background: black;
    overflow: hidden;
  }
  
  /* Video Overlay */
  .video-overlay {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    cursor: pointer;
  }
  
  
  /* React Player */
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  