.description-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Ensure all content is spaced within the section */
    width: 100%;
    height: auto; /* Adjust height to accommodate expanding content */
    padding: 20px 0; /* Reduce padding to fit content better */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 15px;
}

.description-title-row h1 {
    font-family: 'Bierg Artenlt', sans-serif;
    font-size: 80px; /* Reduce title size to fit */
    font-weight: 300;
    line-height: 100px; /* Adjust line height */
    text-align: center;
    color: rgba(219, 10, 37, 1);
    margin: 0; /* Remove any extra margins */
}

.description-title-row span {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
}

.description-text-row {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.description-text-row p {
    font-family: 'Jockey One', sans-serif;
    font-size: 24px; /* Reduce font size to help fit text */
    color: rgba(255, 255, 255, 0.85); /* Slight opacity for readability */
    text-align: center;
    max-width: 90%; /* Spread the text over a larger width */
    line-height: 1.6; /* Adjust line height */
    margin: 0; /* Remove any margins to fit content better */
    padding: 0 50px; /* Add some padding to the sides */
    margin-top: 50px;
}

.description-link-row {
    margin-top: 40px;
    margin-bottom: 40px;
}

.description-link-row button {
    font-family: 'Jockey One', sans-serif;
    font-size: 24px; /* Adjust font size for button */
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    font-weight: lighter;
}

/* Media Query for Mobile */
@media (max-width: 1024px) {
    .description-title-row h1 {
        font-size: 40px; /* Adjust title for smaller screens */
        line-height: 60px;
    }


    .description-text-row p {
        font-size: 14px; /* Reduce text size */
    }

    .description-link-row button {
        font-size: 16px; /* Adjust button size */
    }
}
