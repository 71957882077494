@font-face {
    font-family: 'Bierg Artenlt';
    src: url('./assets//fonts//biergartenlt.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Jockey One';
    src: url('./assets//fonts//JockeyOne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    scroll-behavior: smooth;
}